/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  padding: 0%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: url('./assets/homeImg.gif') no-repeat fixed; */
  background-size: cover;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Define the scrollbar styles */
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  /* Width of the scrollbar */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #6e6f6f;
  /* Color of the scrollbar track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: rgb(34, 5, 88);
  border-radius: 5px;
  /* Color of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80a870;
  /* Color of the scrollbar handle on hover */
}

.datamap-state {
  fill: linear-gradient(
    151.77deg,
    rgba(179, 204, 222, 0.096) 5.25%,
    rgba(255, 255, 255, 0.6) 90.52%
  );
  stroke: #4a9bd4;
  /* border: 1.42px solid #4A9BD4 */
}

/* Tab Responsive Desgin */
/* Center the tab navigation */
.ant-tabs-nav {
  justify-content: center;
  border-radius: 1rem !important;
  margin-top: 1rem !important;
}

/* Style the tab items */
.ant-tabs-tab {
  display: flex; 
  align-items: center;
  justify-content: center;
  background-color: #fafafa !important;;
  color: #9CA3AF !important;;
  padding: 0.5rem 8rem !important; 
  font-size: 16px !important;
  border-radius: 0.5rem !important;
}


:where(.css-dev-only-do-not-override-5wsri9).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 4px !important;
}

:where(.css-5wsri9).ant-tabs .ant-tabs-tab+.ant-tabs-tab{

  margin: 0 0 0 4px !important;

}
/* Style for active tab */
.ant-tabs-tab-active {
  background-color: #ffffff !important;
  color: #000000 !important;
  /* border-radius: 2rem !important; */
}

/* Customize the underline (ink bar) */
.ant-tabs-ink-bar {
  border-bottom-left-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  height: 3.5px !important;
}

/* table design */

table .ant-table-thead .ant-table-cell {
  background: rgba(0, 60, 93, 0.05) !important;
  /* border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; */
}

.TableBorder {
  /* border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom: 2px solid #482e51 !important; */
  overflow: hidden;
}

.ant-table-tbody > tr > td {
  border-color: #dde1ef !important;
}

:where(.css-dev-only-do-not-override-17sk1pa).ant-table-wrapper
  .ant-table-container {
  /* border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important; */
}

.ant-table-thead .ant-table-cell {
  padding: 0.5rem !important;
}

.ant-table-cell {
  /* color: #482E51 !important; */
  padding: 0.7rem !important;
}

.ant-statistic-content-value {
  font-weight: 600;
}

/* Hide all columns by default on small screens */
@media only screen and (max-width: 425px) {
  .ant-table {
    font-size: 12px !important;
  }

  .ant-table-thead .ant-table-cell {
    padding: 0.4rem !important;
    font-size: 10px !important;
  }

  .ant-table-cell {
    padding: 0.5rem !important;
    font-size: 9px !important;
  }

  .ant-table-tbody > tr > td {
    padding: 0.3rem !important;
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 325px) {
  .ant-table {
    font-size: 10px !important;
  }

  .ant-table-thead .ant-table-cell {
    padding: 0.3rem !important;
    font-size: 8px !important;
  }

  .ant-table-cell {
    padding: 0.4rem !important;
    font-size: 9px !important;
  }

  .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
    font-size: 9px !important;
  }
}

@media (max-width: 768px) {
  /* .ant-table .column-basic, */
  .ant-table .column-advance,
  .ant-table .column-enterprise {
    display: none;
  }

  .ant-table .column-feature,
  .ant-table .column-basic {
    display: table-cell;
  }
}

/* Show all columns on larger screens */
@media (min-width: 769px) {
  .ant-table .column-feature,
  .ant-table .column-basic,
  .ant-table .column-advance,
  .ant-table .column-enterprise {
    display: table-cell;
  }
}


.ant-pagination-simple .ant-pagination-simple-pager {
  visibility: hidden;
  width: 0;
  margin: 0;
  padding: 0;
}


.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  margin: 0 5px; 
}


.tooltip {
  transition: opacity 0.2s ease;
  pointer-events: none; 
}
