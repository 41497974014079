.App {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.app-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: Hug (1, 062.78px) px;
  margin: 0 auto;
}

.content {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0px 80px 40px 80px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .content {
    padding: 40px 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    padding: 40px 15px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .content {
    padding: 20px !important;
  }
}

.left-background,
.right-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1.5;
  margin-top: 2rem;
}

.left-background {
  left: 0;
  width: 15rem;
  background-image: url("./assets/backgroundimages/LeftImage.png");
  position: absolute;
  max-height: 22rem;
}

.right-background {
  right: 0;
  width: 18rem;
  max-height: 22rem;
  background-image: url("./assets/backgroundimages/RightImage.png");
}

@media (max-width: 767px) {
  .left-background {
    width: 10rem;
    max-height: 15rem;
    left: -40px;
    top: -30px;
  }

  .right-background {
    width: 12rem;
    max-height: 15rem;
    right: -40px;
    top: -30px;
  }
}

/* Target Ant Design Typography Title based on label prop */
.ant-typography[label="1"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 120%;
  text-align: center;
  background: linear-gradient(90deg, #8dc63f 30%, #007dc1 60%);
  /* background: linear-gradient(90deg, #8dc63f 10%, #007dc1 70%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.ant-typography[label="2"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #003c5d;
}

.ant-typography[label="3"] {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 510 !important;
  font-size: 30px !important;
  line-height: 120% !important;
  text-align: center !important;
  color: #353e4c !important;
}

.ant-typography[label="4"] {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #4b5563;
}

.ant-typography[label="5"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #003c5d;
}

.ant-typography[label="6"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #003c5d;
}

.ant-typography[label="7"] {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #4b5563;
}

.ant-typography[label="8"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #4b5563;
}

.ant-typography[label="9"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-transform: capitalize;
  color: #353e4c;
}

.ant-typography[label="10"] {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 150%;
  color: #003c5d;
}

/* ...........................Media Queries for Responsive Design */

/* Mobile Devices */
@media (max-width: 576px) {
  .ant-typography[label="1"] {
    font-size: 39px;
    padding-top: 4rem !important;
  }

  .ant-typography[label="2"] {
    font-size: 35px;
    letter-spacing: 0.5px;
    line-height: 125%;
  }

  .ant-typography[label="3"] {
    font-size: 25px;
  }
  .ant-typography[label="4"] {
    font-size: 15px;
  }

  .ant-typography[label="9"] {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
  }
}

/* Tablets */
@media (min-width: 577px) and (max-width: 768px) {
  .ant-typography[label="1"] {
    font-size: 42px;
    padding-top: 6rem !important;
  }

  .ant-typography[label="2"] {
    font-size: 34px;
    letter-spacing: 0.8px;
  }

  .ant-typography[label="3"] {
    font-size: 25px;
  }
}

/* Laptops and small desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .ant-typography[label="1"] {
    font-size: 50px;
    padding-top: 8rem !important;
  }

  .ant-typography[label="2"] {
    font-size: 36px;
  }

  .ant-typography[label="3"] {
    font-size: 30px;
  }
}

/* Larger screens (1024px and above) */
@media (min-width: 1025px) {
  .ant-typography[label="1"] {
    font-size: 58px;
    padding: 8rem 8rem 0rem 8rem !important;
  }

  .ant-typography[label="2"] {
    font-size: 40px;
  }

  .ant-typography[label="3"] {
    font-size: 38px;
    padding: 0rem 2rem !important;
  }
}
